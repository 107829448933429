/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductConfigurableAttributes {
    margin-block-start: 15px;
    @include mobile {
        margin-block-start: 25px;
    }
}

[dir="ltr"] .ProductConfigurableAttributes-SwatchList{
    padding: 0;
    margin-block-start: 0;
}

.ProductConfigurableAttributes-SwatchList,
.ProductConfigurableAttributes-DropDownList{
    margin-bottom: 0;
    @include mobile{
        margin-bottom: 12px;
    }
}

.ProductAttributeValue{
    margin-bottom: 10px;
}

.ProductConfigurableAttributes-SwatchList{
    justify-content: center;
}
.ProductPage{
    .ProductConfigurableAttributes-SwatchList{
        justify-content: flex-start;
    }
}
