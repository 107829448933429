/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$black};
    --option-size: 20px;
    --option-text-color: #{$black}; // Move to root
    --option-border-color: var(--color-dark-gray);

    @include mobile {
        --option-border-color: var(--color-dark-gray);
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    &-Color,
    &-String,
    &-Image,
    &-Text {
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin-inline-end: 12px;
        &.Field{
            margin: 0;
        }
    }

    &-String {
        padding: 0 10px;
        margin-block-end: 6px;
    }

    &-Image {
        border-radius: 50%;
        object-fit: cover;
        padding: 0;
        position: relative;
        width: 34px;
        height: 34px;
    }

    &-Image-Overlay {
        --option-check-mark-background: var(--color-white);

        inset-block-start: 0;
        inset-inline-start: 0;
        margin: 0;
        position: absolute;
    }

    &-Color {
        box-shadow: inset 0 0 0 1px #979797;
        box-shadow: inset 0 0 0 1px var(--option-border-color);
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);

        will-change: box-shadow, background-color;

        &::before,
        &::after {
            content: '';
            position: absolute;

            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
        }

        &::before {
            height: calc(var(--option-size) + 6px);
            width: calc(var(--option-size) + 6px);

            border-radius: inherit;
            box-shadow: inset 0 0 0 2px $theme-red-color;
            inset-inline-start: -3px;
            inset-block-start: -3px;
        }

        &::after {
            height: calc(var(--option-size) / 5);
            inset-inline-start: calc(var(--option-size) / 3.3);
            inset-block-start: calc(var(--option-size) / 3.3);
            width: calc(var(--option-size) / 2.5);

            transform: rotate(-45deg);

            [dir="rtl"] & {
                transform: scale(-1, 1) rotate(45deg);
            }

            border-inline-start: 2px solid var(--option-check-mark-background);
            border-block-end: 2px solid var(--option-check-mark-background);
        }

        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        border-radius: var(--button-border-radius);
        line-height: calc(var(--option-size) - 2px);
        min-width: var(--option-size);
        font-weight: 400;
        width: auto;
        text-align: center;

        &_isSelected {
            --option-border-color: var(--primary-base-color);
            font-weight: 600;
        }

        span {
            font-size: 14px;
            color: $default-primary-base-color;
        }
    }

    &-String {
        &_isSelected {
            --option-text-color: var(--primary-base-color);
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;

        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
            margin: 0;
        }

        label {
            &:first-of-type {
                padding-block-end: 0;
                order: 1;
                text-align: start;

                overflow: hidden;
                text-overflow: ellipsis;

                //display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 24px;

                .input-control {
                    inset-inline-start: 0;
                    margin: 0;
                    width: 17px;
                    height: 17px;
                    min-width: 18px;
                    vertical-align: top;
                    border: solid 1px $grey-4;

                    @include desktop {
                        align-self: self-start;
                        margin-block-start: 4px;
                    }
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include desktop {
                    border-color: var(--primary-base-color);
                }
            }

            .input-control {
                &::after {
                    @include desktop {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }

        @include desktop {
            padding-block-end: 6px;
            padding-inline: 0;
        }
    }

    &:hover {
        @include desktop {
            --option-border-color: var(--primary-base-color);
            --option-text-color: var(--primary-base-color);
        }

        text-decoration: none;
    }

    &_isNotAvailable {
        opacity: 1;
        cursor: default;
        pointer-events: all;

        .ProductAttributeValue-String{
            color: $grey-4 !important;
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                right: 0;
                border-top: 1px solid $grey-4;
                -webkit-transform:rotate(311deg);
                -moz-transform:rotate(311deg);
                -ms-transform:rotate(311deg);
                -o-transform:rotate(311deg);
                transform:rotate(311deg);
            }
        }

        .ProductAttributeValue-Color {
            &::before {
                @include not-available;
                display: none;
                background-color: var(--option-check-mark-background);
            }
        }
    }
}
