/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductSizechartPopup{
    .Popup-Content{
        width: 800px;
    }
}
.ProductSizeGuide{
    &-Warpper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > img{
            width: 100px;
            margin-right: 30px;
        }
    }
    &-Details{
        h1{
            font-size: 26px;
            font-weight: 700;
            color: $default-primary-base-color;
            margin-block-end: 10px;
            line-height: 34px;
        }
        .ProductPrice{
            margin-block-end: 6px;
        }
    }
}