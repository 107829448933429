/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --price-color: #{$black};
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #808080;
}

.ProductPrice {
    color: $default-primary-base-color;
    font-weight: 700;
    font-size: inherit;
    line-height: 1;
    vertical-align: middle;
    margin-block-end: 0;

    @include desktop {
        min-height: inherit;
    }

    @include mobile {
        min-height: inherit;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    del {
        font-size: 14px;
        font-weight: 700;
        opacity: 1;
        display: inline-block;
        color: $default-primary-base-color;
        margin-inline-end: 12px;
    }

    &-HighPrice {
        color: var(--price-discount-color);
    }

    &-PriceValue {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        color: $default-primary-base-color;
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        margin-block-start: 4px;
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;
        display: none !important;
    }

    &_hasDiscount{
        .ProductPrice-Price{
            .ProductPrice-PriceValue{
                color: $theme-red-color;
            }
        }
    }
}
.ProductPage .ProductActions-Schema .ProductPrice span.price-label { 
    font-size: 14px;
    font-weight: 400;
    margin-right: 2px;
}
.ProductActions .ProductAttr-Taxes {
    float: left;
    width: 100%;
}
.ProductActions .ProductAttr-Discount{
    float: left;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount ins.ProductPrice-Price span.ProductPrice-PriceValue {
    font-size: 14px;
    color: #212529;
    font-weight: normal;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price del.ProductPrice-HighPrice {
    margin: 0 12px;
    font-size: 14px;
    color: #c5c5c5;
    font-weight: normal;
}
.ProductActions .ProductAttr-Discount {
    font-size: 14px;
    font-weight: 400;
    color: #e31e3c;
    margin-bottom: 5px;
    margin-left: 10px;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price,
.Popup-Content .ProductPrice_hasDiscount.ProductActions-Price {
    display: flex;
    flex-direction: row; 
}
.ProductActions-PriceWrapper {
    float: left; 
}
.ProductActions-PriceWrapper span.ProductPrice-Price span.ProductPrice-PriceValue {
    font-size: 14px;
    color: #212529;
    font-weight: normal;
}
    .ProductActions-PriceWrapper .ProductAttr-Taxes {
    width: 100%;
    float: left;
    margin-top: 10px;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price  span.price-label,
.Popup-Content .ProductPrice.ProductPrice_hasDiscount.ProductActions-Price  span.price-label {
    order: 2;
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;    color: #c5c5c5;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price  del.ProductPrice-HighPrice,
.Popup-Content p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price  del.ProductPrice-HighPrice {
    order: 3;
    margin: 0;
    margin-left: 2px;
}
.ProductActions-PriceWrapper p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price .ProductPrice-Price,
.Popup-Content p.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price .ProductPrice-Price{
    order: 1;
}
 
.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price del.ProductPrice-HighPrice {
    margin: 0 12px;
    font-size: 14px;
    color: #c5c5c5;
    font-weight: normal;
}
.ProductPrice.ProductPrice_hasDiscount.ProductActions-Price span.ProductPrice-PriceValue {
    font-size: 14px;
    color: #212529;
    font-weight: normal;
}
