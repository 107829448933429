/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: $grey-3;
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    height: fit-content;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    width: 100%;

    &:hover {
        @include desktop {
            box-shadow: none;
            z-index: 4;

            .ProductCard-VisibleOnHover {
                background: var(--product-card-background);
                width: inherit;
                opacity: 1;
                box-shadow: 0 3px 6px rgba(0, 0, 0, .1),
                0 6px 16px rgba(0, 0, 0, .1),
                0 9px 28px 8px rgba(0, 0, 0, .1);
                z-index: -1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                padding-block-start: 0;
                padding-block-end: 10px;
                padding-inline: 15px;
                position: absolute;
                inset-block-start: 100%;
            }
        }
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    &-Content {
        background: var(--product-card-background);
        padding-block: 0 16px;
        display: flex;
        flex-direction: column;

        @include desktop {
            padding-inline: 0;
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        margin: 0;
        padding-block-end: 0;
        overflow: inherit;
        text-overflow: inherit;
        white-space: inherit;
        font-size: 16px;
        font-weight: 700;
        color: $default-primary-base-color;
        margin-bottom: 7px;
        @include mobile {
            font-size: 14px;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;
        display: none;
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 134%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-FigureReview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        @include desktop {
            margin-block-end: 0;
            padding: 0;
        }
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;
        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 7px;
    }

    &-Discount{
        font-size: 14px;
        font-weight: 700;
        color: $theme-red-color;
    }

    &-Price {
        width: 100%;

        @include desktop {
            font-size: 0px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    &-Figure {
        flex-grow: 1;
        .ProductWishlistButton{
            position: absolute;
            top: 7px;
            right: 10px;
            z-index: 9;
        }
    }

    &-Label{
        position: absolute;
        top: 10px;
        font-size: 14px;
        color: $default-primary-base-color;
        text-transform: uppercase;
        border-bottom: solid 2px $grey-2;
        border-right: solid 2px $grey-2;
        padding: 7px;
        line-height: 15px;
        @include mobile {
            font-size: 12px;
            padding: 6px;
        }
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 0;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block-start: 5px;
        > * {
            margin-inline-end: 5px;
        }
    }

    &-VisibleOnHover {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 10px;
        padding-inline: 15px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: inherit;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    .ProductAttributeValue-Color:hover::before,
    .ProductAttributeValue-Color:hover::after,
    .ProductAttributeValue-Image-Overlay:hover::before,
    .ProductAttributeValue-Image-Overlay:hover::after{
        display: none;
    }

    .ProductAttributeValue-Color::before,
    .ProductAttributeValue-Image-Overlay::before{
        display: none;
    }

}


.ProductListPage{
   .ProductCard{
       .ProductConfigurableAttributes{
           .ProductAttributeValue-Color{
               &:after{
                   display: none;
               }
           }
       }
   } 
}