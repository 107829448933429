/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductAplus {
    &-Content {
        margin-block-end: 130px;
        text-align: center;
        @include mobile {
            margin-block-end: 50px;
        }
        img,
        iframe {
            margin-block-end: 23px;
        }
        .video-container {
            overflow: hidden;
            position: relative;
            width: 100%;
            margin-block-end: 23px;
        }
        .video-container::after {
            padding-top: 56.25%;
            display: block;
            content: "";
        }
        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        h3 {
            margin: 0;
            margin-block-end: 10px;
            font-size: 40px;
            color: $default-primary-base-color;
            line-height: 50px;
            @include mobile {
                font-size: 26px;
                line-height: 30px;
            }
        }
        p {
            max-width: 840px;
            font-size: 16px;
            line-height: 27px;
            margin: 0 auto;
            @include mobile {
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}
