/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .ProductPage {
    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-end: 133px;
        margin-inline: 16px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 0;

        @include desktop {
            grid-column-gap: 80px;
            grid-template-areas: '. actions';
            padding-block-end: 90px;

            .ProductActions {
                grid-area: actions;
                .ExpandableContentFilter{
                    .ExpandableContentFilter{
                        &-Content {
                            .ProductAttributes{
                                &-AttributeBlock{
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }

    }

    .CustomHeader{
        display: none;
    }

    .ProductName-Wrapper{
        padding-block-end: 12px;
        margin-block-end: 15px;
        border-bottom: solid 2px $default-primary-base-color;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
        @include mobile {
            padding-block-end: 9px;
            margin-block-end: 18px;
        }
        .ProductActions-Title{
            font-size: 26px;
            font-weight: 700;
            line-height: 31px;
            color: $default-primary-base-color;
            margin: 0;
            @include mobile {
                width: 100%;
                margin-bottom: 5px;;
            }
        }
        .ProductActions-Section_type_sku{
            align-items: flex-start;
            flex: 0 0 100%;
            margin-top: 5px;
        }
    }
    .ProductActions-Schema{
        .ProductPrice{
            min-height: auto;
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 5px;
            del{
                font-size: 16px;
                font-weight: 700;
                color: $default-primary-base-color;
                opacity: 1;
            }
            &-PriceValue{
                font-size: 16px;
            }
        }
    }
    .ProductActions{
        .ProductConfigurableAttributes-Title{
            margin: 0px;
            margin-bottom: 15px;
            font-size: 14px;
            color: $default-primary-base-color;
            span{
                font-weight: normal;
                padding-left: 3px;
            }
        }
        .ProductConfigurableAttributes-SwatchList{
            padding-top: 0;
            padding-left: 4px;
        }
        .ProductAttributeValue-Color,
        .ProductAttributeValue-Image-Overlay{
            width: 34px;
            min-height: 34px;
        }
        .ProductAttributeValue-Color::before,
        .ProductAttributeValue-Image-Overlay::before{
            width: 44px;
            height: 44px;
            top: -5px;
            left: -5px;
        }
        .ProductAttributeValue-Color::after,
        .ProductAttributeValue-Image-Overlay::after{
            top: 12px;
            left: 12px;
            display: none;
        }
        .ProductConfigurableAttributes-SwatchList,
        .ProductConfigurableAttributes-DropDownList{
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: solid 1px $grey-4;
            @include mobile {
                margin-bottom: 24px;
            }
        }
        .ProductConfigurableAttributes > div:last-child .ProductConfigurableAttributes-SwatchList,
        .ProductConfigurableAttributes > div:last-child .ProductConfigurableAttributes-DropDownList{
            border-bottom: none;
            padding-bottom: 0px;
            margin-bottom: 18px;
        }
        .ProductAttributeValue-String{
            width: 34px;
            height: 34px;
            line-height: 34px;
            padding:0;
            border-radius: 500%;
            font-size: 12px;
            margin-right: 22px;
            margin-bottom: 12px;
            font-weight: 700;
            color: $default-primary-base-color;
        }
        .ProductAttributeValue-String_isSelected{
            border-color: $theme-red-color;
        }
    }
    .ProductActions-AddToCartWrapper{
        margin-block-start: 0;
        margin-block-end: 25px;
    }
    .ProductActions-Qty.Field{
        margin-inline-end: 10px;
        border: solid 1px $default-primary-base-color;
        [type='number']{
            font-size: 16px;
            color: $default-primary-base-color;
            width: 28px;
            min-width: 28px;
        }
        [type='number'] ~ button{
            border: none;
            width: 30px;
            height: 46px;
        }
    }
    .ProductActions-AddToCartWrapper .ProductActions-AddToCart{
        margin-inline-end: 10px;   
    }
    .ProductActions-ActionButtons{
        .ProductWishlistButton{
            border: solid 1px $default-primary-base-color;
            height: 48px;
            width: 90px;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
        }
    }
    .ProductInformation-Description{
        border-top: solid 1px $grey-4;
        padding-block-start: 23px;
        @include mobile {
            border-top: none;
            padding-block-start: 0px;
            background: #F4F4F4;
            padding: 15px;
            margin-block-end: 50px;
        }
        h3{
            font-size: 16px;
            color: $default-primary-base-color;
            margin: 0 0 10px 0;
            @include mobile {
                text-align: center;
                font-size: 22px;
                margin-block-end: 14px;
            }
        }
        p{
            font-size: 14px;
            line-height: 30px;
            color: $default-primary-base-color;
            margin-block-end: 18px;
        }
    }
    .ProductLinks-Wrapper{
        max-width: 1080px;
        .ProductLinks-Title{
            margin: 0;
            padding: 0;
            margin-block-end: 22px;
            text-align: center;
            font-size: 26px;
            color: $default-primary-base-color;
            font-weight: 700;
        }
        .ProductLinks-List{
            grid-template-columns: inherit;
            grid-gap: inherit;
            display: block;
        }
    }
    .ProductActions-Schema{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        color: $default-primary-base-color;
        @include mobile {
            display: block;
        }
        .offer_label{
            font-size: 14px;
            display: block;
            margin-top: 5px;
            span, strong{
                font-weight: 700;
            }
        }
    }
    .ProductGallery-Additional{
        position: absolute;
        top: 260px;
        left: 40px;
        transform: translateX(-50%);
        .CarouselScroll{
            transform:  rotate(90deg);
            --carousel-scroll-gap: 50px !important;
            margin-top: 0;
        }
        .CarouselScrollItem{
            transform: rotate(-90deg);
            width: 80px;
            height: 119px;
            border: none;
            filter: opacity(1);
            &_isActive{
                border-bottom: solid 3px $default-primary-base-color;
            }
        }
    }
    .CarouselScroll-ContentWrapper{
        width: 580px;
    }
    .ProductGallery{
        margin-left: 0;
        margin-right: 0;
        padding-left: 110px;
        @include mobile {
            padding-left: 0;
        }
    }
    .ProductAlerts-Wrapper{
        .ProductAlerts-InStock{
            font-size: 16px;
            color: $default-primary-base-color;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            padding: 15px;
            border: solid 1px $default-primary-base-color;
            &:hover{
                background: $default-primary-base-color;
                color: $white;
            }
        }
    }
    .ProductLinks{
        margin-bottom: 90px;
        @include mobile {
            margin-bottom: 0;
        }
    }
}


.ProductActions-AddToCart + .ProductAlerts-Wrapper{
    display: none;
}