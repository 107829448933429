/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGallerySliderPopup {
    .Popup-Content{
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;
    }
    .imageWrapper {
        height: 100%;

        .Image {
            height: 100%;
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
            .Image-Image{
                height: auto !important;
            }
        }
    }

    .Thumb {
        &-Wrapper {
            display: flex;
            height: 80px;
            position: absolute;
            bottom: 0;
            left: 0;

            button {
                width: 100px;
            }
            
            .Image {
                width: 100%;
                height: 100%;
            }
        }
    }
}