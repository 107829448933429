/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .ProductAlerts {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include desktop {
            width: calc(100% - 100px);
            margin-right: 10px;
        }
        @include mobile {
            width: calc(100% - 100px);
            margin-right: 10px;
        }
        .ProductAlerts-InStock{
            height: 48px;
            padding: 0 !important;
        }
    }

    &-InStock,
    &-PriceDrop {
        text-align: left;
        color: var(--primary-base-color);
        cursor: pointer;
        font-size: 14px;

        &:hover {
            color: var(--primary-dark-color);
        }

        [dir="rtl"] & {
            text-align: right;
        }
    }
}
