/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
  --product-information-background: var(--secondary-base-color);
}

.ProductAttributes {
  &-Group {
    font-size: 14px;
    padding-top: 20px;
    @include mobile {
      padding-top: 0;
    }
  }
  &-Wrapper {
    padding: 0;

    @include desktop {
      padding: 2rem;
    }
  }

  // &-ExpandableContentButton {
  //     @include after-mobile {
  //         display: none;
  //     }
  // }

  &-ExpandableContentContent {
    &_isContentExpanded {
      @include mobile {
        padding: 0 1.4rem;
      }
    }
  }

  &-Description,
  &-Attributes {
    width: 100%;
  }

  &-Attributes {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5em;
  }

  &-ValueLabel,
  &-AttributeLabel {
    text-overflow: ellipsis;
    font-family: var(--font-staatliches);
    font-size: 20px;
    line-height: 1;
    font-size: 13px;
    padding: 8px 0;
  }
  &-AttributeLabel {
    border: 0;
    @include mobile {
      padding-bottom: 0;
      padding-top: 0;
    }
    &::after {
      content: ":";
      display: inline-block;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  &-ValueLabel {
    font-weight: 700;
    border: 0;
    @include mobile {
      padding-left: 0.7rem;
      margin-bottom: 1.4rem;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.ExpandableContentFilter {
  @include desktop {
    padding-bottom: 15px;
  }
  border-bottom: 1px solid $grey-4;
  &-Content {
    .ProductAttributes {
      &-AttributeBlock {
        margin-left: 12px !important;
        margin-top: 10px;
        .ProductAttributeValue {
          padding: 0;
          font-weight: normal;
        }
      }
    }
  }
}
