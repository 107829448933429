/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGallerySlider{
    width: 650px;
    padding-left: 113px;
    position: relative;
    .ProductGallerySliderMain{
    .Image {
        height: auto;
    }
    .Image-Image{
        position: inherit;
    }

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;
    
        &:focus {
            outline: none;
        }
    
        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    
    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    
        &:before,
        &:after {
            content: "";
            display: table;
        }
    
        &:after {
            clear: both;
        }
    
        .slick-loading & {
            visibility: hidden;
        }
    }
    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
        [dir="rtl"] & {
            float: right;
        }
        img {
            display: block;
        }
        &.slick-loading img {
            display: none;
        }
    
    
        &.dragging img {
            pointer-events: none;
        }
    
        .slick-initialized & {
            display: block;
        }
    
        .slick-loading & {
            visibility: hidden;
        }
    
        .slick-vertical & {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
    }
    .slick-arrow.slick-hidden {
        display: none;
    }
    
    /* Arrows */
    
    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: 32px;
        width: 19px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background: url(images/arrow.png);
        @include mobile {
            height: 20px;
            width: 12px;
            background-size: 100%;
            top: 35%;
        }
    }
    
    .slick-prev {
        left: 10px;
        z-index: 999;
    }
    
    
    .slick-next {
        right: 10px;
        z-index: 999;
        transform: translate(0, -50%) rotate(180deg);
    }
    
    /* Dots */
    
    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }
    
    .slick-dots {
        bottom: 0;
        list-style: none;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex !important;
        flex-wrap: wrap;
        li {
            position: relative;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            flex-grow: 1;
            margin: 0;
            &:before{
                display: none;
            }
            button {
                border: 0;
                background: transparent;
                display: block;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                cursor: pointer;
                background: $grey-2;
                height: 3px;
                width: 100%;
                &:hover, &:focus {
                    outline: none;
                    background: $default-primary-base-color;
                }
            }
            &.slick-active button {
                background: $default-primary-base-color;
            }
        }
    }
    
}
}


.ProductGallerySliderThumb{
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;
    
        &:focus {
            outline: none;
        }
    
        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    
    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    
        &:before,
        &:after {
            content: "";
            display: table;
        }
    
        &:after {
            clear: both;
        }
    
        .slick-loading & {
            visibility: hidden;
        }
    }
    .slick-slide {
        float: left;
        height: 100%;
        min-height: 1px;
        padding: 10px 0;
        [dir="rtl"] & {
            float: right;
        }
        img {
            display: block;
        }
        &.slick-loading img {
            display: none;
        }
    
    
        &.dragging img {
            pointer-events: none;
        }
    
        .slick-initialized & {
            display: block;
        }
    
        .slick-loading & {
            visibility: hidden;
        }
    
        .slick-vertical & {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
        img{
            border-bottom: solid 4px transparent !important;
        }
        &.slick-current{
            img{
                border-bottom: solid 4px red !important;
            }
        }
    }
    .slick-slider{
        height: 715px;
        overflow: hidden;
        .slick-slide{
            display: block;
            height: auto;
            border: 1px solid transparent;
            .ProductGalleryThumbnailImage{
                position: inherit;
                height: auto;
                overflow: inherit;
                display: block;
                .Image-Image{
                    position: inherit;
                }
                img{
                    background-color: transparent;
                }
            }
        }
    }
      
      .slick-slide {
        height: auto;
      }

      .slick-arrow{
        position: relative;
        font-size: 0px;
        height: 20px;
        width: 100%;
        cursor: pointer;
        &:before{
            border: solid #7A8BA0;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 5px;
            content: "";
            position: absolute;
            left: 50%;
        }
        &.slick-prev{
            &::before{
                top: 0;
                transform: rotate(-135deg) translateY(-50%);
                -webkit-transform: rotate(-135deg) translateY(-50%);
            }
        }
        &.slick-next{
            &::before{
                bottom: 0;
                transform: rotate(45deg) translateX(-50%);
                -webkit-transform: rotate(45deg) translateX(-50%);
            }
        }
          
      }
}