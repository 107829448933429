/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// util class, is applied on <html> on fullscreen image
.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 690px;
    margin-inline: 45px;

    @media screen and (max-width: 1500px) {
        height: 645px;
    }

    @media screen and (max-width: 1400px) {
        height: 639px;
    }

    @media screen and (max-width: 1300px) {
        height: 620px;
    }

    @media screen and (max-width: 1200px) {
        height: 560px;
    }

    @include narrow-desktop {
        min-width: 507px;
    }

    @include mobile {
        height: calc(100vh/2);
        margin-block-end: 20px;
    }

    &-Additional {
        flex: 0 0 auto;

        @include mobile {
            display: none;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;
            z-index: 99999;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }
    }

    &-SliderWrapper {
        flex: 1;
        z-index: 99;
        .Slider-Arrow_isPrev{
            left: 0 !important;
        }
        .Slider-Arrow_isNext{
            right: 0 !important;
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);
        padding-bottom: 30px;

        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
        @include mobile {
            height: 100% !important;
            width: 100% !important;
        }
    }
    &-SliderImage{
        .Image-Image{
            object-fit: contain;
        }
    }
}
